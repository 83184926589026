.modal{
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile_container_pic1 {
    width: 6vw;
    height: 6vw;
    border-radius: 5vw;
    object-fit: cover;
}

.profile_container1 {
    width: 100%;
    height: 16vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1vh;
}
.proselect{
    cursor: pointer;
    color: royalblue;
}

@media screen and (max-width: 540px) {

    .profile_container_pic1 {
        width: 30vw;
        height: 30vw;
        border-radius: 15vw;
    }
}