.post {
    overflow: hidden;
    max-width: 500px;
    background-color: #fafafa;
    border-radius: 4px;
    border: 1px solid lightgray;
    margin: 4px;
}

.post_header {
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fafafa;
}

.post_profile_img {
    width: 30px;
    height: 30px;
    background-color: #111;
    border-radius: 20px;
}

.post_header h2 {
    font-weight: 400;
    margin-left: 5px;
}

.post .post_img {
    width: 100%;
}

.post .post_img img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.post_bottom_bar {
    width: 100%;
    display: flex;
    background-color: #fafafa;
    align-items: center;
    padding: 10px;
}

.comment_bottom_bar {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    padding: 10px;
}

h5 {
    margin-right: 5px;
    font-size: 1rem;
}

.comm_box {
    display: flex;
    margin-top: 10px;
    border-top: lightgray 1px solid;
}

.comment_input {
    border: none;
    outline: none;
    flex: 1;
}

input[type="text"] {
    outline: none;
}

.post_btn {
    border: none;
    height: 30px;
    outline: none;
    flex: 0;
}