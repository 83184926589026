.sign {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100%;
    margin: 0;
    padding: 0;
    background-color: #fafafa;
    background-image: url(../../bg.jpg);
    display: flex;
    flex-direction: column;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #464646;
    align-items: center;

}
.container1{
    width: 100%;
    height: 100%;
    min-height: 95vh;
    max-height: 100%;
    margin: 0;
    padding: 0;
    background-color: #fafafa;
    background-image: url(../../4799045.jpg);
    display: flex;
    flex-direction: column;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #464646;
    align-items: center;
}
.footer_copy {
    color: #fff;
    position: relative;
    text-align: center;
    
}

.sign h1 {
    margin: 20px;
    font-weight: 400;
    margin-bottom: 10vh;
    color: rgb(90, 86, 86);
}

.fpass {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.fpass h2 {
    margin: 20px;
    font-weight: 400;
    color: rgb(90, 86, 86);
}

.sign .container {
    margin-top: 10vh;
    width: 400px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
    align-items: center;
    margin-bottom: 15vh;
}
.sign_update .container {
    width: 400px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
    align-items: center;
    z-index: 10000;
}
.sign .selector {
    width: 100%;
    height: 40px;
    background-color: #eca742;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;
    border: lightgray 1px solid;
    margin-bottom: 20px;
}

.sign .selector span {
    flex: 1;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#btn:hover {
    background-color: #eca742;
}

.uploadBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.uploadBox #form {
    overflow: hidden;
    width: 500px;
    background-color: #ffff;
    border-radius: 4px;
    border: 1px solid lightgray;
    margin: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    align-items: center;
}

.uploadBox h1 {
    color: slategrey;
    background-color: #fff;
}

.uploadBox #form img {
    background-color: rgb(26, 25, 25);
    object-fit: contain;
    width: 100%;
    max-height: 400px;
}

.uploadBox #form #btn-select {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.uploadBox #form #btn-select label {
    margin-right: 10px;
}

.home_container {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

#home_container_box {
    width: 40%;
    height: 50vh;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#home_container_box1 {
    width: 40%;
    height: 80vh;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #ccc4c4;
    border-radius: 3px;
}

.login_reset_password {
    color: blue;
    text-align: right;
}

.login_reset_password:hover {
    cursor: pointer;
}

.content {
    background-color: white;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.containerC p {
    color: red;
    text-align: center;
}

.drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 400px;
    height: 200px;
    padding: 10px;
    border: 1px dashed #eca742;
}

.profile_container_pic {
    width: 6vw;
    height: 6vw;
    border-radius: 5vw;
    object-fit: cover;
}

.profile_container {
    margin-top: 2vh;
    width: 100%;
    height: 22vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.upload-icon {
    width: 50px;
    height: 50px;
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;
}

.drop-message {
    text-align: center;
    color: #eca742;
    font-family: Arial;
    font-size: 20px;
}

@media screen and (max-width: 450px) {
    .sign .container {
        width: 90vw;
        margin-top: 1vw;
        margin-bottom: 100px;
    }
    .home_container {
        flex-direction: column;
    }
}

@media screen and (max-width: 540px) {
    .home_container {
        flex-direction: column;
    }
    #home_container_box {
        width: 80%;
        height: 50vh;
        margin-bottom: 5vh;
    }
    #home_container_box1 {
        width: 80%;
        height: 80vh;
    }
    .profile_container_pic {
        width: 30vw;
        height: 30vw;
        border-radius: 15vw;
    }
}
.files input {
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 1vw;
    width: 90% !important;
}

.files .files_img{
    width: 20vw;
    height: 20vw;
}

.row_cent{
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.files video{
    width: 40vw;
    height: 30vw;
    background-color: #464646;
}
.files {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40vw;
    height: 40vw;
    margin-top: 10vh;
    background-color: #fff;
 }
 .files .proselect_1{
     color: royalblue !important;
     font-size: 1.5em !important;
     cursor: pointer;
 }

 .inputContainer{
     display: flex;
     flex-direction: column;
 }
.files{ 
    position:relative;
    padding: 5vw;
    border-width: 1px;
    border-color: #eca742;
    border-style: solid;
}

.color input{ background-color:#f1f1f1;}


/* //1428 */
@media screen and (max-width: 800px) {
    #home_container_box {
        width: 45%;
        height: 50vh;
        margin-bottom: 5vh;
    }
    #home_container_box1 {
        width: 45%;
        height: 80vh;
    }
}

@media screen and (max-width: 1428px) {
    .files .files_img{
        width: 15vw;
        height: 15vw;
    }
}

@media screen and (max-width: 1168px) {
    .files .files_img{
        width: 12vw;
        height: 12vw;
    }
}


@media screen and (max-width: 880px) {
    .row_cent{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .files .files_img{
        width: 40vw;
        height: 40vw;
    }
    .files {
        width: 60vw;
        height: 60vw;
    }
}
@media screen and (max-width: 680px) {
    .files {
        width: 90vw;
        height: 90vw;
    }
}